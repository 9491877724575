var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.hideLabel
        ? _c(
            "b-form-group",
            { attrs: { label: _vm.label } },
            [
              _c("v-select", {
                attrs: {
                  id: "supplierEnergyConsumption",
                  options: _vm.mapSuppliers,
                  placeholder: _vm.FormMSG(65, "Select a supplier"),
                  reduce: (option) => option.code,
                  clearable: false,
                },
                on: { "option:selected": _vm.handleOptionSelected },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "selected-option",
                      fn: function ({ code, label }) {
                        return [
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.checkLengthLabelForSelect(label))
                            ),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "option",
                      fn: function ({ code, label }) {
                        return [
                          code === -1
                            ? _c(
                                "div",
                                {
                                  staticClass: "fw-700 pb-1",
                                  on: { click: _vm.handleClickAddNewSupplier },
                                },
                                [
                                  _c(_vm.getLucideIcon("PlusCircle"), {
                                    tag: "component",
                                    attrs: { size: 16, color: "#47C7BF" },
                                  }),
                                  _vm._v(" " + _vm._s(label) + "\n\t\t\t\t"),
                                ],
                                1
                              )
                            : _c("div", [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "option-supplier-to-select d-flex align-items-center",
                                  },
                                  [
                                    _c("div", { staticClass: "w-85" }, [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t" +
                                          _vm._s(label) +
                                          "\n\t\t\t\t\t\t"
                                      ),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "align-items-end" },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn-transparent text-color-burning-tomato btn-to-remove",
                                            attrs: { type: "button" },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.deleteSupplier(code)
                                              },
                                            },
                                          },
                                          [
                                            _c(_vm.getLucideIcon("Trash2"), {
                                              tag: "component",
                                              attrs: { size: 16 },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2715673400
                ),
                model: {
                  value: _vm.supplierSelected,
                  callback: function ($$v) {
                    _vm.supplierSelected = $$v
                  },
                  expression: "supplierSelected",
                },
              }),
            ],
            1
          )
        : _c("v-select", {
            attrs: {
              id: "supplierEnergyConsumption",
              options: _vm.mapSuppliers,
              placeholder: _vm.FormMSG(65, "Select a supplier"),
              reduce: (option) => option.code,
              clearable: false,
            },
            on: { "option:selected": _vm.handleOptionSelected },
            scopedSlots: _vm._u([
              {
                key: "selected-option",
                fn: function ({ code, label }) {
                  return [
                    _c("div", [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(_vm.checkLengthLabelForSelect(label)) +
                          "\n\t\t\t"
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "option",
                fn: function ({ code, label }) {
                  return [
                    code === -1
                      ? _c(
                          "div",
                          {
                            staticClass: "fw-700 pb-1",
                            on: { click: _vm.handleClickAddNewSupplier },
                          },
                          [
                            _c(_vm.getLucideIcon("PlusCircle"), {
                              tag: "component",
                              attrs: { size: 16, color: "#47C7BF" },
                            }),
                            _vm._v(" " + _vm._s(label) + "\n\t\t\t"),
                          ],
                          1
                        )
                      : _c("div", [
                          _c(
                            "div",
                            {
                              staticClass:
                                "option-supplier-to-select d-flex align-items-center",
                            },
                            [
                              _c("div", { staticClass: "w-85" }, [
                                _vm._v(
                                  "\n\t\t\t\t\t\t" +
                                    _vm._s(label) +
                                    "\n\t\t\t\t\t"
                                ),
                              ]),
                              _c("div", { staticClass: "align-items-end" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn-transparent text-color-burning-tomato btn-to-remove",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.deleteSupplier(code)
                                      },
                                    },
                                  },
                                  [
                                    _c(_vm.getLucideIcon("Trash2"), {
                                      tag: "component",
                                      attrs: { size: 16 },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          ),
                        ]),
                  ]
                },
              },
            ]),
            model: {
              value: _vm.supplierSelected,
              callback: function ($$v) {
                _vm.supplierSelected = $$v
              },
              expression: "supplierSelected",
            },
          }),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.dialogTitle,
            size: "lg",
            "hide-header-close": "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            "ok-title": _vm.FormMSG(36, "Save"),
            "cancel-title": _vm.FormMSG(37, "Cancel"),
            "ok-variant": "primary",
            "cancel-variant": "custom-outline-gray",
            "modal-class": "mui-animation",
            fade: false,
          },
          on: {
            ok: function ($event) {
              $event.preventDefault()
              return _vm.handleOk.apply(null, arguments)
            },
            cancel: _vm.handleCancel,
          },
          model: {
            value: _vm.showModal,
            callback: function ($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal",
          },
        },
        [
          _c("div", [
            _c(
              "fieldset",
              {
                staticClass: "my-0 py-0 pb-0",
                class: `${
                  _vm.$screen.width <= 576 ? "card-inside" : "scheduler-border"
                }`,
                attrs: { tyle: "height: 100%" },
              },
              [
                _c(
                  "legend",
                  {
                    staticClass: "text-color-rhapsody-in-blue-2",
                    class: `${
                      _vm.$screen.width <= 576
                        ? "card-inside"
                        : "scheduler-border"
                    }`,
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(_vm.FormMSG(653, "General")) +
                        "\n\t\t\t\t"
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "pt-3 pb-3" },
                  [
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { cols: "8" } },
                          [
                            _c(
                              "b-form-group",
                              { attrs: { label: _vm.FormMSG(689, "Name") } },
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    placeholder: _vm.FormMSG(547, "Name"),
                                    state: !_vm.$v.dataToSend.name.$error,
                                    required: "",
                                  },
                                  model: {
                                    value: _vm.$v.dataToSend.name.$model,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.$v.dataToSend.name,
                                        "$model",
                                        $$v
                                      )
                                    },
                                    expression: "$v.dataToSend.name.$model",
                                  },
                                }),
                                _vm.$v.dataToSend.name.$error === true
                                  ? _c(
                                      "div",
                                      { staticClass: "invalid-feedback" },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              _vm.FormMSG(
                                                514,
                                                "Name is required"
                                              )
                                            ) +
                                            "\n\t\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { staticClass: "pt-28", attrs: { cols: "4" } },
                          [
                            _c(
                              "div",
                              { staticClass: "d-flex align-items-center" },
                              [
                                _c(
                                  "b-form-checkbox",
                                  {
                                    attrs: {
                                      switch: "",
                                      size: "lg",
                                      value: true,
                                      "unchecked-value": false,
                                    },
                                    model: {
                                      value: _vm.dataToSend.isGreen,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.dataToSend, "isGreen", $$v)
                                      },
                                      expression: "dataToSend.isGreen",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.FormMSG(678, "Green supplier")
                                        ) +
                                        "\n\t\t\t\t\t\t\t\t"
                                    ),
                                  ]
                                ),
                                _c(
                                  "info-tooltip",
                                  {
                                    staticClass: "ml-2",
                                    attrs: { "icon-size": 20 },
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.FormMSG(
                                            841,
                                            "A electricity supplier is considered to be a green supplier when they generate all of the electricity they provide from a  environmentally friendly resource (i.e. wind, solar, water, etc)."
                                          )
                                        ) +
                                        "\n\t\t\t\t\t\t\t\t"
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }