<template>
	<div>
		<b-form-group v-if="!hideLabel" :label="label">
			<v-select
				id="supplierEnergyConsumption"
				v-model="supplierSelected"
				:options="mapSuppliers"
				:placeholder="FormMSG(65, 'Select a supplier')"
				:reduce="(option) => option.code"
				:clearable="false"
				@option:selected="handleOptionSelected"
			>
				<template #selected-option="{ code, label }">
					<div>{{ checkLengthLabelForSelect(label) }}</div>
				</template>
				<template #option="{ code, label }">
					<div v-if="code === -1" class="fw-700 pb-1" @click="handleClickAddNewSupplier">
						<component :is="getLucideIcon('PlusCircle')" :size="16" color="#47C7BF" /> {{ label }}
					</div>
					<div v-else>
						<div class="option-supplier-to-select d-flex align-items-center">
							<div class="w-85">
								{{ label }}
							</div>
							<div class="align-items-end">
								<button type="button" class="btn-transparent text-color-burning-tomato btn-to-remove" @click.stop="deleteSupplier(code)">
									<component :is="getLucideIcon('Trash2')" :size="16" />
								</button>
							</div>
						</div>
					</div>
				</template>
			</v-select>
		</b-form-group>
		<v-select
			v-else
			id="supplierEnergyConsumption"
			v-model="supplierSelected"
			:options="mapSuppliers"
			:placeholder="FormMSG(65, 'Select a supplier')"
			:reduce="(option) => option.code"
			:clearable="false"
			@option:selected="handleOptionSelected"
		>
			<template #selected-option="{ code, label }">
				<div>
					{{ checkLengthLabelForSelect(label) }}
				</div>
			</template>
			<template #option="{ code, label }">
				<div v-if="code === -1" class="fw-700 pb-1" @click="handleClickAddNewSupplier">
					<component :is="getLucideIcon('PlusCircle')" :size="16" color="#47C7BF" /> {{ label }}
				</div>
				<div v-else>
					<div class="option-supplier-to-select d-flex align-items-center">
						<div class="w-85">
							{{ label }}
						</div>
						<div class="align-items-end">
							<button type="button" class="btn-transparent text-color-burning-tomato btn-to-remove" @click.stop="deleteSupplier(code)">
								<component :is="getLucideIcon('Trash2')" :size="16" />
							</button>
						</div>
					</div>
				</div>
			</template>
		</v-select>

		<b-modal
			v-model="showModal"
			:title="dialogTitle"
			size="lg"
			hide-header-close
			no-close-on-backdrop
			no-close-on-esc
			:ok-title="FormMSG(36, 'Save')"
			:cancel-title="FormMSG(37, 'Cancel')"
			ok-variant="primary"
			cancel-variant="custom-outline-gray"
			@ok.prevent="handleOk"
			@cancel="handleCancel"
			modal-class="mui-animation"
			:fade="false"
		>
			<div>
				<fieldset class="my-0 py-0 pb-0" :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" tyle="height: 100%">
					<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2">
						{{ FormMSG(653, 'General') }}
					</legend>
					<div class="pt-3 pb-3">
						<b-row>
							<b-col cols="8">
								<b-form-group :label="FormMSG(689, 'Name')">
									<b-form-input
										v-model="$v.dataToSend.name.$model"
										:placeholder="FormMSG(547, 'Name')"
										:state="!$v.dataToSend.name.$error"
										required
									/>
									<div v-if="$v.dataToSend.name.$error === true" class="invalid-feedback">
										{{ FormMSG(514, 'Name is required') }}
									</div>
								</b-form-group>
							</b-col>
							<b-col cols="4" class="pt-28">
								<div class="d-flex align-items-center">
									<b-form-checkbox v-model="dataToSend.isGreen" switch size="lg" :value="true" :unchecked-value="false">
										{{ FormMSG(678, 'Green supplier') }}
									</b-form-checkbox>
									<info-tooltip class="ml-2" :icon-size="20">
										{{
											FormMSG(
												841,
												'A electricity supplier is considered to be a green supplier when they generate all of the electricity they provide from a  environmentally friendly resource (i.e. wind, solar, water, etc).'
											)
										}}
									</info-tooltip>
								</div>
							</b-col>
						</b-row>
					</div>
				</fieldset>
			</div>
		</b-modal>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import { getSupplier, getSuppliers, addSupplier, updateSupplier, deleteSupplier } from '@/cruds/suppliers.crud';
import { noTilde } from '~utils';
import globalMixin from '@/mixins/global.mixin';
import InfoTooltip from './InfoTooltip';
import _ from 'lodash';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

const supplierTypesString = ['electricity', 'gas', 'water'];
const supplierTypesNumber = { electricity: 2, gas: 13, water: 14 };

export default {
	name: 'SupplierEnergyConsumption',
	components: { InfoTooltip },
	mixins: [languageMessages, globalMixin, validationMixin],

	props: {
		label: { type: String, default: '' },
		hideLabel: { type: Boolean, default: false },
		dialogTitle: { type: String, default: '' },
		supplierType: { type: String, validator: (option) => supplierTypesString.includes(option) },
		labelAddSupplier: { type: String, default: '' },
		supplierId: { type: [Number, String], default: 0, required: false }
	},

	data() {
		return {
			showModal: false,
			suppliers: [],
			supplierSelected: null,
			supplierSelectedOriginal: null,

			dataToSend: {
				name: '',
				isGreen: false
			}
		};
	},

	validations: {
		dataToSend: {
			name: { required }
		}
	},

	watch: {
		supplierId: {
			async handler(newVal) {
				if (newVal > 0) {
					const supplier = await getSupplier(newVal);
					this.supplierSelected = {
						code: parseInt(newVal),
						label: supplier.name
					};
					this.supplierSelectedOriginal = _.cloneDeep(this.supplierSelected);
				}
			},
			immediate: true
		}
	},

	computed: {
		mapSuppliers() {
			let res = this.suppliers.map(({ id, name }) => ({
				code: id,
				label: name
			}));

			res.sort((a, b) => {
				if (noTilde(a.label.trim()).toLowerCase() < noTilde(b.label.trim()).toLowerCase()) {
					return -1;
				}
				if (noTilde(a.label.trim()).toLowerCase() > noTilde(b.label.trim()).toLowerCase()) {
					return 1;
				}

				return 0;
			});

			res.unshift({
				code: -1,
				label: this.labelAddSupplier
			});

			return res;
		}
	},

	async created() {
		await this.reloadData();
	},

	methods: {
		async deleteSupplier(code) {
			const action = async () => {
				await deleteSupplier({ id: parseInt(code) });

				this.suppliers = this.suppliers.filter((option) => parseInt(option.id, 10) !== parseInt(code, 10));
			};

			this.confirmModal(action, this.FormMSG(458, 'Are you sure ?'));
		},
		handleCancel() {
			this.supplierSelected = _.cloneDeep(this.supplierSelectedOriginal);
			this.dataToSend = {
				name: ''
			};
			this.showModal = false;
		},
		async handleOk() {
			this.$v.$touch();
			if (!this.$v.$invalid) {
				const supplier = await addSupplier({
					name: this.dataToSend.name,
					type: supplierTypesNumber[this.supplierType],
					isGreen: this.dataToSend.isGreen
				});

				const data = {
					code: parseInt(supplier.id),
					label: this.dataToSend.name
				};

				this.supplierSelected = data;
				this.supplierSelectedOriginal = _.cloneDeep(this.supplierSelected);
				this.suppliers.push({
					id: parseInt(supplier.id),
					name: this.dataToSend.name
				});
				this.showModal = false;

				this.$emit('supplier-energy:change', data);
			}
		},
		handleOptionSelected(payload) {
			if (_.isNil(payload)) {
				this.$emit('supplier-energy:unselect');
				return;
			}
			if (!_.isNil(payload) && payload.code > -1) {
				this.supplierSelectedOriginal = payload;
				this.$emit('supplier-energy:change', payload);
			}
		},
		handleClickAddNewSupplier() {
			this.showModal = true;
		},
		async reloadData() {
			this.suppliers = await getSuppliers({
				name: null,
				type: supplierTypesNumber[this.supplierType]
			});
		}
	}
};
</script>

<style scoped></style>
